import React from "react";
import { SafeAreaProvider, useSafeAreaInsets } from "react-native-safe-area-context";
import { ScrollView, FlatList } from 'react-native';
import { StatusBar } from 'expo-status-bar';
import { Box, Text, Touchable } from '@devsoutinho/components-native';

export default function App() {
  return (
    <SafeAreaProvider>
      <TicTacToeGPT />
    </SafeAreaProvider>
  );
}

function TicTacToeGPT() {
  const insets = useSafeAreaInsets();

  const [state, setState] = React.useState({
    squares: [
      "", "", "",
      "", "", "",
      "", "", ""
    ],
    currentPlayer: 1,
  });

  
  const isPlayerOne = state.currentPlayer === 1;

  React.useEffect(() => {
    if (state.currentPlayer === 2) {
      fetch("https://proxy.mariosouto.com/api/tictactoe", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          state: state.squares,
        }),
      })
      .then((res) => res.json())
      .then((res) => {
        console.log("res", res);
      });
    }
  }, [state.currentPlayer]);

  function handleClick(index: number) {
    const squares = [...state.squares];
    squares[index] = state.currentPlayer === 1 ? "O" : "X";
    setState({
      squares,
      currentPlayer: state.currentPlayer === 1 ? 2 : 1,
    });
  }

  return (
    <ScrollView
      style={{
        backgroundColor: '#FFFFFF',
      }}
      contentContainerStyle={{
        flexGrow: 1
      }}
    >
      <StatusBar />

      <Box
        styleSheet={{
          flex: 1,
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
          marginTop: insets.top,
          marginBottom: insets.bottom,
        }}
      >
        <Box>
          <Text
            styleSheet={{
              fontSize: 28,
              fontWeight: 800,
              textAlign: "center",
              marginBottom: 20,
            }}
          >
            Tic Tac Toe vs 🤖
          </Text>
          <FlatList
            data={state.squares}
            numColumns={3}
            renderItem={({ item, index }) => (
              <Square
                value={item}
                onTap={() => handleClick(index)}
                disabled={!isPlayerOne}
              />
            )}
            keyExtractor={(_, index) => index.toString()}
            scrollEnabled={false}
            style={{
              flexGrow: 0,
            }}
          />
        </Box>
      </Box>
    </ScrollView>
  );
}

interface SquareProps {
  value?: string;
  disabled?: boolean;
  onTap?: () => void;
}
function Square({ value, disabled, onTap }: SquareProps) {
  return (
    <Touchable
      // Replace with onTap
      disabled={disabled}
      onPress={onTap}
      styleSheet={{
        width: 100,
        height: 100,
        backgroundColor: '#FFFFFF',
        borderWidth: 1,
        borderColor: '#000000',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Text
        styleSheet={{
          fontSize: 32,
          fontWeight: 800,
        }}
      >
        {value}
      </Text>
    </Touchable>
  )
}
